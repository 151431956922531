@import "../../../stylesheets/base";

.image-upload {
  width: 100%;

  &--disabled {
    display: none;
  }

  &__input {
    visibility: hidden;
  }

  &__label {
    display: block;
    width: 100%;
    padding: $spacing-300 0;
    text-align: center;
    color: $positive;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%236AB557FF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: $spacing-100;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacing-100;

    &-image {
      width: rem(108);
      height: rem(108);
      object-fit: cover;
      border-radius: $spacing-50;
    }

    &-text {
      color: $ui-secondary;
    }

    &-btn {
      background: none;
      border: none;
      padding: 0;
    }
  }
}
