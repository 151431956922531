$ui-primary: #292929;
$ui-secondary: #999999;
$ui-disabled: #bfbfbf;
$ui-divider: #e0e0e0;
$ui-background: #f1f4f7;
$ui-white: #ffffff;

$stepper-background: #333042;

$accent: #f5761d;
$accent-light: #f9a86f;

$positive: #6ab557;
$positive-light: #a0d094;

$negative: #fb2b16;
$negative-light: #fd786a;
