@import "../../stylesheets/base";

.shared-page {
  text-align: center;
  color: $ui-primary;
  padding: $spacing-400 0 $spacing-200;

  h4 {
    margin: $spacing-400 0 $spacing-100;
  }

  &__details {
    padding: $spacing-200;
    border: 1px solid $ui-divider;
    border-radius: $spacing-100;
    text-align: left;
    margin: $spacing-300 0;

    &--light {
      color: $ui-secondary;
    }
  }

  &__divider {
    border-top: 1px solid $ui-divider;
    margin: $spacing-150 0;
  }

  &__text {
    white-space: pre-line;
  }
}
