.react-pdf {
  &__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__Page {
    max-width: 100%;
    margin: 1em;

    canvas {
      max-width: 100%;
      /* stylelint-disable declaration-no-important */
      height: auto !important;
    }

    &__textContent {
      display: none;
    }
  }
}
