@import "../../../stylesheets/base";

.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__header {
    display: flex;
    background-color: $ui-divider;
    border: 2px solid $ui-divider;
    border-radius: $spacing-100;
    margin: 0 $spacing-200 $spacing-200;
  }

  &__tab-item {
    padding: $spacing-50 0;
    border-radius: rem(6);
    width: 100%;
    text-align: center;

    &--active {
      background-color: $accent;
      color: $ui-white;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12), 0 3px 1px rgba(0, 0, 0, 0.04);
    }
  }

  &__tab {
    flex-grow: 1;
    border-top: 1px solid $ui-divider;
  }
}
