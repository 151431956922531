@import "../../../stylesheets/base";

$variants: (
  primary $ui-primary,
  blanc $ui-white,
  accent $accent,
  positive $positive,
  negative $negative
);

.icon-button {
  border: none;
  background: none;
  padding: 0;

  &__label {
    @each $variant in $variants {
      &--#{nth($variant, 1)} {
        color: #{nth($variant, 2)};
      }
    }
  }
}
