@import "../../../stylesheets/base";

.label {
  &-sm {
    font-size: $label-sm-font-size;
    line-height: $label-sm-line-height;
    letter-spacing: $label-sm-letter-spacing;
    font-weight: $label-sm-font-weight;
  }

  &-md {
    font-size: $label-md-font-size;
    line-height: $label-md-line-height;
    letter-spacing: $label-md-letter-spacing;
    font-weight: $label-md-font-weight;
  }

  &-lg {
    font-size: $label-lg-font-size;
    line-height: $label-lg-line-height;
    letter-spacing: $label-lg-letter-spacing;
    font-weight: $label-lg-font-weight;
  }
}
