@import "../../stylesheets/base";

.work-card {
  &__card {
    border: 1px solid $ui-divider;
    border-radius: $spacing-100;
    padding: $spacing-200 $spacing-200 0;

    & + & {
      margin-top: rem(16);
    }
  }

  &__row {
    display: flex;
    align-items: flex-end;
    margin-bottom: $spacing-100;
  }

  &__icon {
    padding: $spacing-200;
  }

  &__divider {
    border-top: 1px solid $ui-divider;
    margin: $spacing-300 0 $spacing-200 0;
  }

  &__label {
    color: $ui-secondary;
  }

  &__name {
    width: 100%;
    padding-bottom: rem(12);
    border-bottom: solid 1px $ui-divider;
  }

  &__date-field {
    &__label {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-weight: $title-sm-font-weight;
    }

    &__input {
      appearance: none;
      width: 100%;
      font-size: 16px;
      background-color: transparent;
      margin-top: $spacing-100;
      border-radius: $radio-md-border-width;
      border: 1px #e0e0e0 solid;
      padding: 12px 16px;

      &--error {
        border-color: $negative;
      }
    }

    &__text-error {
      color: $negative;
      font-weight: $paragraph-sm-font-weight;
      font-size: $label-xs-font-size;
      margin-top: 3px;
    }
  }
}
