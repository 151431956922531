@import "../../stylesheets/base";

.alternative-maps {
  padding: $spacing-100;

  img {
    max-width: 100%;
  }
}

