@import "../../../stylesheets/base";

.heading {
  font-family: $font-heading;

  &-xs {
    font-size: $title-xs-font-size;
    line-height: $title-xs-line-height;
    letter-spacing: $title-xs-letter-spacing;
    font-weight: $title-xs-font-weight;
  }

  &-sm {
    font-size: $title-sm-font-size;
    line-height: $title-sm-line-height;
    letter-spacing: $title-sm-letter-spacing;
    font-weight: $title-sm-font-weight;
  }

  &-md {
    font-size: $title-md-font-size;
    line-height: $title-md-line-height;
    letter-spacing: $title-md-letter-spacing;
    font-weight: $title-md-font-weight;
  }

  &-lg {
    font-size: $title-lg-font-size;
    line-height: $title-lg-line-height;
    letter-spacing: $title-lg-letter-spacing;
    font-weight: $title-lg-font-weight;
  }

  &-xl {
    font-size: $title-xl-font-size;
    line-height: $title-xl-line-height;
    letter-spacing: $title-xl-letter-spacing;
    font-weight: $title-xl-font-weight;
  }

  &-xxl {
    font-size: $title-xxl-font-size;
    line-height: $title-xxl-line-height;
    letter-spacing: $title-xxl-letter-spacing;
    font-weight: $title-xxl-font-weight;
  }
}
