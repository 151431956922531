@import "../../stylesheets/base";

.add-driver {
  color: $ui-primary;
  padding-top: $spacing-200;

  &__heading {
    margin-bottom: 1rem;
  }

  &__input {
    margin-bottom: $spacing-200;
  }
}

.add-driver__divider {
  display: flex;
  align-items: center;
  margin: $spacing-400 0 $spacing-300;

  &::before,
  &::after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: $ui-divider;
  }

  &--text {
    &::before {
      margin-right: $spacing-100;
    }

    &::after {
      margin-left: $spacing-100;
    }
  }
}
