/* stylelint-disable */
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  src: url("assets/font/inter-v3-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("assets/font/inter-v3-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/font/inter-v3-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("assets/font/inter-v3-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("assets/font/inter-v3-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("assets/font/inter-v3-latin-regular.svg#Inter") format("svg"); /* Legacy iOS */
}

/* inter-500 - latin */
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url("assets/font/inter-v3-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("assets/font/inter-v3-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/font/inter-v3-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("assets/font/inter-v3-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("assets/font/inter-v3-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("assets/font/inter-v3-latin-500.svg#Inter")
      format("svg"); /* Legacy iOS */
}

/* inter-700 - latin */
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  src: url("assets/font/inter-v3-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("assets/font/inter-v3-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/font/inter-v3-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("assets/font/inter-v3-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("assets/font/inter-v3-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("assets/font/inter-v3-latin-700.svg#Inter")
      format("svg"); /* Legacy iOS */
}
/* stylelint-enable */

$font-body: Inter, "Arial, sans-serif";
$font-heading: Inter, "Arial, sans-serif";

$paragraph-xs-font-size: #{rem(14)};
$paragraph-xs-line-height: #{rem(20)};
$paragraph-xs-letter-spacing: #{rem(-0.09)};
$paragraph-xs-font-weight: 400;

$paragraph-sm-font-size: #{rem(14)};
$paragraph-sm-line-height: #{rem(20)};
$paragraph-sm-letter-spacing: #{rem(-0.09)};
$paragraph-sm-font-weight: 400;

$paragraph-md-font-size: #{rem(16)};
$paragraph-md-line-height: #{rem(24)};
$paragraph-md-letter-spacing: #{rem(-0.18)};
$paragraph-md-font-weight: 400;

$paragraph-lg-font-size: #{rem(18)};
$paragraph-lg-line-height: #{rem(28)};
$paragraph-lg-letter-spacing: #{rem(-0.26)};
$paragraph-lg-font-weight: 400;

$label-xs-font-size: #{rem(12)};
$label-xs-line-height: #{rem(16)};
$label-xs-letter-spacing: #{rem(-0.01)};
$label-xs-font-weight: 500;

$label-sm-font-size: #{rem(14)};
$label-sm-line-height: #{rem(18)};
$label-sm-letter-spacing: #{rem(-0.09)};
$label-sm-font-weight: 500;

$label-md-font-size: #{rem(16)};
$label-md-line-height: #{rem(20)};
$label-md-letter-spacing: #{rem(-0.18)};
$label-md-font-weight: 500;

$label-lg-font-size: #{rem(18)};
$label-lg-line-height: #{rem(22)};
$label-lg-letter-spacing: #{rem(-0.26)};
$label-lg-font-weight: 500;

$label-xl-font-size: #{rem(20)};
$label-xl-line-height: #{rem(24)};
$label-xl-letter-spacing: #{rem(-0.34)};
$label-xl-font-weight: 500;

$title-xs-font-size: #{rem(18)};
$title-xs-line-height: #{rem(22)};
$title-xs-letter-spacing: #{rem(-0.26)};
$title-xs-font-weight: 600;

$title-sm-font-size: #{rem(20)};
$title-sm-line-height: #{rem(24)};
$title-sm-letter-spacing: #{rem(-0.33)};
$title-sm-font-weight: 600;

$title-md-font-size: #{rem(24)};
$title-md-line-height: #{rem(30)};
$title-md-letter-spacing: #{rem(-0.47)};
$title-md-font-weight: 600;

$title-lg-font-size: #{rem(28)};
$title-lg-line-height: #{rem(36)};
$title-lg-letter-spacing: #{rem(-0.59)};
$title-lg-font-weight: 700;

$title-xl-font-size: #{rem(32)};
$title-xl-line-height: #{rem(40)};
$title-xl-letter-spacing: #{rem(-0.69)};
$title-xl-font-weight: 700;

$title-xxl-font-size: #{rem(36)};
$title-xxl-line-height: #{rem(44)};
$title-xxl-letter-spacing: #{rem(-0.79)};
$title-xxl-font-weight: 700;

$radio-lg-border-width: #{rem(8)};
$radio-md-border-width: #{rem(7)};
$radio-sm-border-width: #{rem(6)};

$radio-gap: #{rem(20)};
$radio-border-width: #{rem(2)};
$radio-outline-offset: #{rem(-2)};

$radio-lg-diameter: #{rem(30)};
$radio-md-diameter: #{rem(26)};
$radio-sm-diameter: #{rem(22)};

$radio-lg-spacing: #{rem(32)};
$radio-md-spacing: #{rem(28)};
$radio-sm-spacing: #{rem(24)};
