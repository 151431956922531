@import "../../stylesheets/base";

.site-notes {
  padding: $spacing-200;
  border-top: 1px solid $ui-divider;
  white-space: pre-line;
}

.notes-heading {
  margin: $spacing-400 0 $spacing-200;
}
