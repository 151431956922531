@import "../../../stylesheets/base";

$variants: (
  positive $positive $ui-background,
  negative $negative $ui-background
);

/* stylelint-disable indentation */
$floating: (
  lg $label-lg-font-size $label-lg-font-weight $label-lg-letter-spacing
    $label-lg-line-height,
  md $label-md-font-size $label-md-font-weight $label-md-letter-spacing
    $label-md-line-height,
  sm $label-sm-font-size $label-sm-font-weight $label-sm-letter-spacing
    $label-sm-line-height
);

.input__text-group {
  &--lg {
    .input__text-label {
      font-size: $label-md-font-size;
      font-weight: $label-md-font-weight;
      letter-spacing: $label-md-letter-spacing;
      line-height: $label-md-line-height;
      margin-bottom: 12px;
    }

    .input__text-input {
      font-size: $paragraph-lg-font-size;
      font-weight: $paragraph-lg-font-weight;
      letter-spacing: $paragraph-lg-letter-spacing;
      line-height: $paragraph-lg-line-height;
      padding: 10px;
      margin-bottom: 12px;
    }

    .input__text-caption,
    .input__text-validation {
      font-size: $paragraph-md-font-size;
      font-weight: $paragraph-md-font-weight;
      letter-spacing: $paragraph-md-letter-spacing;
      line-height: $paragraph-md-line-height;
    }
  }

  &--md {
    .input__text-label {
      font-size: $label-md-font-size;
      font-weight: $label-md-font-weight;
      letter-spacing: $label-md-letter-spacing;
      line-height: $label-md-line-height;
      margin-bottom: 8px;
    }

    .input__text-input {
      font-size: $paragraph-md-font-size;
      font-weight: $paragraph-md-font-weight;
      letter-spacing: $paragraph-md-letter-spacing;
      line-height: $paragraph-md-line-height;
      padding: 8px;
      margin-bottom: 8px;
    }

    .input__text-caption,
    .input__text-validation {
      font-size: $paragraph-md-font-size;
      font-weight: $paragraph-md-font-weight;
      letter-spacing: $paragraph-md-letter-spacing;
      line-height: $paragraph-md-line-height;
    }
  }

  &--sm {
    .input__text-label {
      font-size: $label-xs-font-size;
      font-weight: $label-xs-font-weight;
      line-height: $label-xs-line-height;
      letter-spacing: $label-xs-letter-spacing;
      margin-bottom: 4px;
    }

    .input__text-input {
      font-size: $paragraph-sm-font-size;
      font-weight: $paragraph-sm-font-weight;
      line-height: $paragraph-sm-line-height;
      letter-spacing: $paragraph-sm-letter-spacing;
      padding: 6px;
      margin-bottom: 4px;
    }

    .input__text-caption,
    .input__text-validation {
      font-size: $paragraph-xs-font-size;
      font-weight: $paragraph-xs-font-weight;
      line-height: $paragraph-xs-line-height;
      letter-spacing: $paragraph-xs-letter-spacing;
    }
  }

  .input__text-label {
    display: block;
    color: $ui-primary;

    .input__text-label-text {
      display: block;
    }
  }

  .input__text-container {
    position: relative;

    // stylelint-disable selector-pseudo-class-no-unknown
    :global(.validation) {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    // stylelint-enable selector-pseudo-class-no-unknown
  }

  .input__text-input {
    background: $ui-background;
    border: none;
    box-shadow: 0 0 0 1px $ui-divider;
    color: $ui-primary;
    padding: 10px 16px;
    width: 100%;
    font-family: $font-body;

    &:hover {
      box-shadow: 0 0 0 1px $ui-divider;
      transition: all 0.25s ease-in-out;
    }

    &:focus {
      box-shadow: 0 0 0 2px $positive;
      outline: none;
      transition: all 0.25s ease-in-out;
    }

    &:active {
      box-shadow: 0 0 0 2px $ui-primary;
      transition: all 0.25s ease-in-out;
    }

    &:disabled {
      opacity: 0.5;
    }

    &::placeholder {
      color: $ui-secondary;
    }

    &--rounded {
      border-width: 1px;
      border-radius: $spacing-50;
    }

    &--pill {
      border-width: 1px;
      border-radius: 24px;
    }

    &--accent {
      background: $ui-background;

      &:active {
        box-shadow: 0 0 0 2px $positive;
      }

      &:focus {
        box-shadow: 0 0 0 3px $positive-light;
      }
    }

    &--outline {
      background: $ui-white;
    }

    &--negative {
      padding-right: 40px;
    }

    &--minimal {
      background: $ui-white;
      box-shadow: none;
      border-bottom: 1px solid $ui-divider;
      border-radius: 0;
    }

    @each $variant in $variants {
      &--#{nth($variant, 1)} {
        background: #{nth($variant, 3)};
        box-shadow: 0 0 0 1px #{nth($variant, 2)};

        // stylelint-disable selector-pseudo-class-no-unknown
        + :global(.validation) path {
          stroke: #{nth($variant, 2)};
          transition: all 0.25s ease-in-out;
        }

        &:hover {
          box-shadow: 0 0 0 1px #{nth($variant, 2)};
          transition: all 0.25s ease-in-out;

          + :global(.validation) path {
            stroke: #{nth($variant, 2)};
            transition: all 0.25s ease-in-out;
          }
        }

        &:active {
          box-shadow: 0 0 0 2px #{nth($variant, 2)};

          + :global(.validation) path {
            stroke: #{nth($variant, 2)};
            transition: all 0.25s ease-in-out;
          }
        }

        &:focus {
          box-shadow: 0 0 0 3px #{nth($variant, 2)};

          + :global(.validation) path {
            stroke: #{nth($variant, 2)};
            transition: all 0.25s ease-in-out;
          }
        }
        // stylelint-enable selector-pseudo-class-no-unknown
      }
    }
  }

  .input__text-caption,
  .input__text-validation {
    color: $ui-secondary;
    display: block;
  }

  &.input__text-group--negative {
    .input__text-validation {
      color: $negative;
    }
  }

  &.input__text-group--icon {
    .input__text-input {
      padding-left: 40px;
    }

    // stylelint-disable selector-pseudo-class-no-unknown
    :global(.prefix) {
      position: absolute;
      top: 10px;
      left: 10px;
    }
    // stylelint-enable selector-pseudo-class-no-unknown
  }

  &.input__text-group--floating {
    position: relative;
    margin-bottom: 10px;

    .input__text-label {
      color: $ui-primary;
      margin: 0;
      position: absolute;
      top: 25px;
      left: 16px;
      transform: translateY(-8px);
      transition: all 0.25s ease-in-out;
    }

    @each $float in $floating {
      &.input__text-group--#{nth($float, 1)} {
        .input__text-label {
          font-size: #{nth($float, 2)};
          font-weight: #{nth($float, 3)};
          letter-spacing: #{nth($float, 4)};
          line-height: #{nth($float, 5)};
        }
      }
    }

    .input__text-input {
      padding: 22px 16px 0;
      line-height: 2;

      &::placeholder {
        color: transparent;
      }

      // stylelint-disable selector-max-specificity
      &:active,
      &:focus {
        &::placeholder {
          color: $ui-secondary;
        }

        + .input__text-label {
          font-size: $paragraph-md-font-size;
          font-weight: $paragraph-md-font-weight;
          line-height: $paragraph-md-line-height;
          letter-spacing: $paragraph-md-letter-spacing;
          transform: translateY(-20px);
        }
      }

      &:not(:placeholder-shown) {
        + .input__text-label {
          font-size: $paragraph-md-font-size;
          font-weight: $paragraph-md-font-weight;
          line-height: $paragraph-md-line-height;
          letter-spacing: $paragraph-md-letter-spacing;
          transform: translateY(-20px);
        }
      }

      &:read-only {
        padding-left: 0;

        &::placeholder {
          color: $ui-secondary;
        }

        + .input__text-label {
          font-size: $paragraph-md-font-size;
          font-weight: $paragraph-md-font-weight;
          line-height: $paragraph-md-line-height;
          letter-spacing: $paragraph-md-letter-spacing;
          left: 0;
          transform: translateY(-20px);
        }
      }
      // stylelint-enable selector-max-specificity
    }
  }
}
