@import "../../../stylesheets/base";

$sizes: (
  lg $radio-lg-diameter $radio-lg-spacing,
  md $radio-md-diameter $radio-md-spacing,
  sm $radio-sm-diameter $radio-sm-spacing
);

$variants: (
  default $ui-secondary,
  accent $accent,
  positive $positive,
  negative $negative
);

.input__radio-group {
  @each $size in $sizes {
    &--#{nth($size, 1)} {
      .input__radio-circle {
        &::before {
          height: #{nth($size, 2)};
          width: #{nth($size, 2)};
        }

        &::after {
          height: #{nth($size, 2)};
          width: #{nth($size, 2)};
        }
      }

      .input__radio-label {
        padding-right: calc(#{nth($size, 3)} + 4px);
      }
    }
  }

  @each $variant in $variants {
    &--#{nth($variant, 1)} {
      .input__radio-circle {
        &::before {
          border: 1px solid $ui-divider;
        }
      }

      .input__radio:checked ~ .input__radio-circle {
        &::before {
          border: none;
          background-color: #{nth($variant, 2)};
          background-image: url("assets/icons/Check.svg");
          background-repeat: no-repeat;
          background-position: 50%;
        }
      }

      .input__radio:checked:focus ~ .input__radio-circle {
        &::before {
          box-shadow: none;
        }
      }
    }
  }

  .input__radio-label {
    position: relative;
    margin-right: $radio-gap;
    margin-bottom: $radio-gap;
  }

  &--horizontal {
    display: flex;
  }

  &--vertical {
    display: flex;
    flex-direction: column;

    .input__radio-label {
      border-bottom: 1px solid $ui-divider;
      margin: 0;
      padding: $spacing-200 0;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &.disabled {
    opacity: 0.24;
  }

  .input__radio-circle {
    &::before,
    &::after {
      position: absolute;
      content: "";
      border-radius: 50%;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      border-color: transparent;
      background: transparent;
      right: $radio-outline-offset;
    }
  }

  .input__radio {
    opacity: 0;
    position: absolute;
    width: 0;
    margin: 0;
    height: 0;

    &:disabled ~ .input__radio-circle {
      pointer-events: none;
    }
  }
}
