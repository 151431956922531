@import "../stylesheets/base";

$colors: (
  primary $ui-primary,
  secondary $ui-secondary,
  disabled $ui-disabled,
  divider $ui-divider,
  background $ui-background,
  blanc $ui-white,
  accent $accent,
  accent-light $accent-light,
  positive $positive,
  positive-light $positive-light,
  negative $negative,
  negative-light $negative-light
);

.colorpill {
  padding: $spacing-125;
  font-size: rem(14);
  margin: $spacing-50;

  @each $color in $colors {
    &--#{nth($color, 1)} {
      background-color: #{nth($color, 2)};
    }
  }

  &--primary {
    color: $ui-white;
  }

  &--white {
    border: 1px solid $ui-divider;
  }
}

/* stylelint-disable selector-pseudo-class-no-unknown */
:export {
  primary: $ui-primary;
  secondary: $ui-secondary;
  disabled: $ui-disabled;
  divider: $ui-divider;
  background: $ui-background;
  blanc: $ui-white;
  accent: $accent;
  accent-light: $accent-light;
  positive: $positive;
  positive-light: $positive-light;
  negative: $negative;
  negative-light: $negative-light;
}
