@import "../../stylesheets/base";

.add-button {
  display: flex;
  align-items: center;

  color: $accent;

  svg {
    width: 25px;
    height: 25px;

    display: block;
  }

  span {
    margin-right: rem(10);
  }
}

.drivers-list {
  margin-bottom: rem(24);
}

.driver-item {
  border: 1px solid $ui-divider;
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.02);
  border-radius: 8px;

  padding: rem(11) rem(16);

  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: rem(60);

  & + & {
    margin-top: rem(8);
  }
}
