@import "../../../stylesheets/base";

.text {
  &.truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-sm {
    font-size: $paragraph-sm-font-size;
    line-height: $paragraph-sm-line-height;
    letter-spacing: $paragraph-sm-letter-spacing;
    font-weight: $paragraph-sm-font-weight;
  }

  &-md {
    font-size: $paragraph-md-font-size;
    line-height: $paragraph-md-line-height;
    letter-spacing: $paragraph-md-letter-spacing;
    font-weight: $paragraph-md-font-weight;
  }

  &-lg {
    font-size: $paragraph-lg-font-size;
    line-height: $paragraph-lg-line-height;
    letter-spacing: $paragraph-lg-letter-spacing;
    font-weight: $paragraph-lg-font-weight;
  }
}
