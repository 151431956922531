@import "../../stylesheets/base";

.issue-reported {
  padding-top: rem(120);
  text-align: center;

  &__phone {
    color: $accent;
    text-decoration: none;
  }
}
