@import "../../../stylesheets/base";

.combobox {
  cursor: pointer;
  width: 100%;

  &--disabled {
    cursor: not-allowed;
    opacity: 0.32;
  }

  &__label {
    display: block;
    margin-bottom: $spacing-100;
  }

  &__input-container {
    position: relative;
  }

  &__input {
    display: flex;
  }

  &__input-field {
    box-sizing: border-box;
    background-color: $ui-background;
    outline: 0;
    padding: rem(10);
    width: 100%;
    border: 1px solid $ui-divider;
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: $label-lg-font-size;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__control {
    transition: box-shadow 0.25s;
    cursor: pointer;
    font-size: 1rem;
    border: 1px solid $ui-divider;
    border-left: none;
    border-right: none;
    background-color: $ui-background;
    padding: $spacing-100;
    display: flex;
    align-items: center;
    outline: none;

    &:disabled {
      cursor: not-allowed;
    }

    &:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid $ui-divider;
    }
  }

  &__icon {
    margin: 0 $spacing-50;
    border: none;
    outline: 0;
  }

  &__menu {
    position: absolute;
    z-index: 2;
    width: 100%;
    background-color: $ui-white;
    transition: box-shadow 0.25s;
    visibility: hidden;
    margin-top: $spacing-50;
    box-shadow: 0 0 0 1px grey, 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    outline: none;
    border-radius: 4px;
    padding: 0 $spacing-200;

    &.open {
      // stylelint-disable declaration-colon-newline-after, value-list-comma-newline-after
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
      visibility: visible;
    }
  }

  &__option {
    padding: $spacing-200 0;
    border-bottom: 1px solid $ui-divider;

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &--empty {
      opacity: 0.32;
      background-color: $ui-white;
      cursor: not-allowed;
      padding: $spacing-100 $spacing-200;
    }
  }
}
