@import "../../../stylesheets/base";

$sizes: (
  lg $spacing-300 $spacing-150 $spacing-200,
  md $spacing-250 $spacing-125 $spacing-150,
  sm $spacing-200 $spacing-100 $spacing-125
);

$variants: (
  default $ui-secondary,
  accent $accent,
  positive $positive,
  negative $negative
);

.input__checkbox-label {
  display: flex;
  font-weight: $paragraph-md-font-weight;
  color: $ui-primary;
  align-items: center;

  &.start-align {
    align-items: flex-start;
  }
}

.input__checkbox {
  flex-shrink: 0;
  appearance: none;
  border-radius: $spacing-50;
  outline: none;
  cursor: pointer;
  align-items: center;
  margin: 0;

  &::after {
    display: none;
    content: "";
    background-image: url("assets/icons/Check.svg");
    background-repeat: no-repeat;
    background-position: 50%;
  }

  &:checked {
    border: none;

    &::after {
      display: block;
    }
  }

  &.disabled {
    opacity: 0.24;
    pointer-events: none;
  }

  @each $size in $sizes {
    &--#{nth($size, 1)} {
      height: #{nth($size, 2)};
      width: #{nth($size, 2)};
      margin-right: #{nth($size, 3)};

      &::after {
        height: #{nth($size, 2)};
        width: #{nth($size, 2)};
        background-size: #{nth($size, 4)};
      }
    }
  }

  @each $variant in $variants {
    &--#{nth($variant, 1)} {
      border: 1px solid #{nth($variant, 2)};

      &:checked {
        background-color: #{nth($variant, 2)};
      }
    }
  }
}
