@import "../../../stylesheets/base";

$variants: (
  ui $ui-primary $ui-white $ui-secondary,
  accent $accent $ui-white $accent-light,
  positive $positive $ui-white $positive-light,
  negative $negative $ui-white $negative-light
);

$sizes: (
  sm $spacing-100 $spacing-150,
  md $spacing-125 $spacing-200,
  lg $spacing-150 $spacing-250
);

.btn {
  position: relative;
  display: inline-block;
  appearance: none;
  outline: none;
  border: none;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  font-family: $font-body;
  font-weight: 500;

  &__text {
    &--loading {
      color: transparent;
    }
  }

  &--disabled {
    opacity: 0.32;
  }

  &--loading {
    color: transparent;
  }

  &--rounded {
    border-radius: $spacing-50;
  }

  &--pill {
    border-radius: $spacing-350;
  }

  @each $size in $sizes {
    &--#{nth($size, 1)} {
      padding: #{nth($size, 2)} #{nth($size, 3)};
    }
  }

  &--fullsize {
    width: 100%;
  }

  &--solid {
    @each $variant in $variants {
      &--#{nth($variant, 1)} {
        background: #{nth($variant, 2)};
        box-shadow: 0 0 0 1px #{nth($variant, 2)};
        color: #{nth($variant, 3)};

        &:hover {
          background: #{nth($variant, 4)};
          box-shadow: 0 0 0 1px #{nth($variant, 4)};
        }

        &:active {
          background: #{nth($variant, 2)};
          box-shadow: 0 0 0 1px #{nth($variant, 2)};
        }

        &:focus {
          box-shadow:
            0 0 0 3px #{nth($variant, 2)},
            inset 0 0 0 1px #{nth($variant, 3)};
        }
      }
    }
  }

  &--outline {
    @each $variant in $variants {
      &--#{nth($variant, 1)} {
        background: #{nth($variant, 3)};
        box-shadow: 0 0 0 1px #{nth($variant, 2)};
        color: #{nth($variant, 2)};

        &:hover {
          box-shadow: 0 0 0 1px #{nth($variant, 4)};
          color: #{nth($variant, 4)};
        }

        &:active {
          box-shadow: 0 0 0 1px #{nth($variant, 2)};
        }

        &:focus {
          box-shadow: 0 0 0 3px #{nth($variant, 2)};
        }
      }
    }
  }


  &--ghost {
    @each $variant in $variants {
      &--#{nth($variant, 1)} {
        background: #{nth($variant, 3)};
        box-shadow: 0 0 0 1px #{nth($variant, 3)};
        color: #{nth($variant, 2)};
        padding-left: 0;
        padding-right: 0;

        &:hover {
          text-underline-offset: $spacing-25;
          text-decoration-line: underline;
        }

        &:active {
          box-shadow: 0 0 0 1px #{nth($variant, 2)};
        }

        &:focus {
          box-shadow: 0 0 0 3px #{nth($variant, 2)};
        }
      }
    }
  }
}

.link {
  display: inline-block;
  outline: none;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  @each $size in $sizes {
    &--#{nth($size, 1)} {
      padding: #{nth($size, 2)} 0;
    }
  }

  &--rounded {
    border-radius: $spacing-100;
  }

  @each $variant in $variants {
    &--#{nth($variant, 1)} {
      color: #{nth($variant, 2)};
    }
  }
}
