@import "../../../stylesheets/base";

.modal {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $ui-white;

  display: flex;
  flex-direction: column;

  overflow: auto;

  &__header {
    display: flex;
    padding: $spacing-250 $spacing-200;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin-right: $spacing-200;
    }

    span {
      display: block;
    }
  }
}
