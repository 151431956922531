@import "../../../stylesheets/base";

.stepper {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  background-color: $stepper-background;
  align-items: center;
  padding: $spacing-200 0 $spacing-150;

  &__title {
    color: $ui-white;
    margin-bottom: $spacing-50;
  }

  &__subtitle {
    color: $ui-disabled;
    margin-bottom: $spacing-100;
  }

  &__steps {
    display: flex;
    flex-direction: row;
  }

  &__step {
    display: block;
    border: 1px solid $ui-secondary;
    border-radius: $spacing-150;
    color: $ui-disabled;
    min-width: rem(28);
    min-height: rem(24);
    line-height: rem(22);
    text-align: center;
    margin: 0 $spacing-50;

    &.current-step {
      background-color: $positive;
      border-color: $positive;
      color: $ui-white;
    }

    &.past-step {
      background-color: $ui-disabled;
      border-color: $ui-disabled;
      color: $ui-primary;
    }
  }

  &__links {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: $spacing-300;

    button {
      padding: 0 $spacing-250;
    }
  }

  &__share {
    position: absolute;
    top: 7px;
    right: 7px;
  }
}
